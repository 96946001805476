<template>
  <v-sheet elevation="2">
    <v-toolbar
      v-if="title != null"
      height="64px"
      flat
    >
      <!-- Title -->
      <v-toolbar-title>
        <span>{{ title }}</span>
      </v-toolbar-title>
      <v-spacer/>
      <!-- Title Actions -->
      <!-- Only works if a title is supplied. -->
      <slot name="title-actions"/>
    </v-toolbar>

    <div class="px-3 pb-3 pt-1">
      <slot>
        Yep 🐸 Block
      </slot>
    </div>
  </v-sheet>
</template>

<script>
export default {
  props: {
    title: { type: String, default: null }
  }
}
</script>
