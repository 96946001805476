<template>
  <div>
    <!-- Filters for small devices -->
    <v-expansion-panels v-if="!$vuetify.breakpoint.mdAndUp" class="mb-3">
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ $t('common.filters') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <SessionsFilter
            @searchRequested="onSearchRequested"
            @filterChanged="onFilterChanged"
            :loading="isLoading"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row>
      <!-- Content -->
      <v-col cols="12" md="8" lg="9">
        <SessionsTable
          :items="sessions"
          :total-size="totalSize"
          :page-size="pageSize"
          :page-number="pageNumber"
          :loading="isLoading"
          :headers="headers"
          :features="features"
          :title="$t('sessions.table.title')"
          @pageChanged="pageChanged"
          @itemUpdated="onItemUpdated"
          @uploadFinished="onUploadFinished"
          @itemDeleted="onItemDeleted"
          @sortingChanged="onSortingChanged"
          @refreshRequested="onRefreshRequested"
          :id="tableId"
        >
        </SessionsTable>
      </v-col>

      <!-- Filters for medium and large devices -->
      <v-col md="4" lg="3" v-if="$vuetify.breakpoint.mdAndUp">
        <Block :title="$t('common.filters')">
          <SessionsFilter
            @searchRequested="onSearchRequested"
            @filterChanged="onFilterChanged"
            :loading="isLoading"
          />
        </Block>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { mapActions, mapGetters } from 'vuex'
import Block from '@/components/Block'
import SessionsFilter from '@/components/filters/SessionsFilter'
import SessionsTable from '@/components/tables/SessionsTable'

export default {
  components: { Block, SessionsFilter, SessionsTable },
  data () {
    return {
      sessions: [],
      totalSize: 0,
      pageSize: 25,
      pageNumber: 1,
      latestFilterData: null,
      scrollOptions: {
        easing: 'easeInOutQuad',
        duration: 300,
        offset: 0
      },
      tableId: 'sessions-table',
      amountOfBusyRequests: 0,
      latestSorting: undefined
    }
  },
  computed: {
    ...mapGetters('account', ['isAdmin']),
    isLoading () {
      return this.amountOfBusyRequests > 0
    },
    features () {
      return this.isAdmin ? ['update', 'archive', 'upload', 'delete', 'details'] : ['upload']
    },
    headers () {
      if (this.isAdmin) {
        return [
          { text: '', value: 'indications', sortable: false },
          { text: this.$t('sessions.table.columns.date'), value: 'startTime' },
          { text: this.$t('sessions.table.columns.system'), value: 'system' },
          { text: this.$t('sessions.table.columns.driver'), value: 'driver' },
          { text: this.$t('sessions.table.columns.vehicle'), value: 'vehicle' },
          { text: this.$t('sessions.table.columns.duration'), value: 'duration' },
          { text: this.$t('sessions.table.columns.quantity-fuelled'), value: 'quantityFuelled' },
          { text: '', value: 'actions', sortable: false, align: 'right' }
        ]
      } else {
        return [
          { text: '', value: 'indications', sortable: false },
          { text: this.$t('sessions.table.columns.date'), value: 'startTime' },
          { text: this.$t('sessions.table.columns.system'), value: 'system' },
          { text: this.$t('sessions.table.columns.driver'), value: 'driver' },
          { text: this.$t('sessions.table.columns.vehicle'), value: 'vehicle' },
          { text: this.$t('sessions.table.columns.duration'), value: 'duration' },
          { text: this.$t('sessions.table.columns.quantity-fuelled'), value: 'quantityFuelled' }
        ]
      }
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('softwareVersions', ['fetchSoftwareVersions']),
    ...mapActions('drivers', ['fetchDrivers']),
    ...mapActions('projects', ['fetchAllProjects']),
    ...mapActions('systems', ['fetchAllSystems']),
    ...mapActions('vehicles', ['fetchAllVehicles']),
    ...mapActions('errorCauses', ['fetchAllErrorCauses']),
    ...mapActions('errors', ['fetchAllErrors']),
    ...mapActions('sessions', ['fetchSessions']),
    onFilterChanged (filter) {
      this.latestFilterData = filter
    },
    onItemUpdated (updatedSession) {
      // Try to find the updated session among the currently shown sessions.
      const index = this.sessions.findIndex((session) => session.id === updatedSession.id)
      if (index !== -1) {
        // Update the session to the new values.
        // $set is a special method to allow vue to react to array changes.
        // See https://vuejs.org/v2/guide/reactivity.html for more info.
        this.$set(this.sessions, index, updatedSession)
      }
    },
    async onRefreshRequested () {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestFilterData, this.latestSorting)
    },
    async pageChanged (newPageNumber) {
      // Update the current page number.
      this.pageNumber = newPageNumber
      // Fetch data with the new page number.
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestFilterData, this.latestSorting)
    },
    async onSearchRequested (filter) {
      this.latestFilterData = filter
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestFilterData, this.latestSorting)
    },
    async onUploadFinished (amountUploaded) {
      if (amountUploaded === 0) return
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestFilterData, this.latestSorting)
    },
    async onItemDeleted (deletedSession) {
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestFilterData, this.latestSorting)
    },
    async onSortingChanged (newSorting) {
      this.latestSorting = newSorting
      await this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestFilterData, this.latestSorting)
    },
    fetchPageDebounced: debounce(async function (pageNumber, pageSize, filter, sorting) {
      await this.fetchPage(pageNumber, pageSize, filter, sorting)
    }, 500),
    async fetchPage (pageNumber, pageSize, filter, sorting) {
      this.amountOfBusyRequests++

      try {
        // Scroll back to top of table.
        this.$vuetify.goTo(`#${this.tableId}`, this.scrollOptions)

        const requests = []
        // An admin requires some extra data.
        if (this.isAdmin) {
          requests.push(...[
            this.fetchAllErrors()
          ])
        }
        // Fetch all software versions, drivers and vehicles (so they are already fetched for being used in a specific table cell/slot).
        requests.push(...[
          this.fetchSoftwareVersions(),
          this.fetchDrivers(),
          this.fetchAllVehicles()
        ])

        await Promise.all(requests)
        // Fetch all sessions.
        const pagedResponse = await this.fetchSessions({ pageNumber, pageSize, filter, sorting })
        this.sessions = pagedResponse.items
        this.totalSize = pagedResponse.totalSize
      } catch (error) {
        this.showSnackbar({
          role: 'error',
          messages: [this.$t('errors.loading-data-failed')],
          duration: 5000
        })
      } finally {
        this.amountOfBusyRequests--
      }
    }
  },
  created () {
    this.pageNumber = parseInt(this.$route.query.page || this.pageNumber)
  },
  async mounted () {
    this.latestFilterData = this.$store.state.sessionFilter.sessionFilter

    const requests = [
      this.isAdmin ? this.fetchAllProjects() : Promise.resolve(),
      this.isAdmin ? this.fetchAllErrorCauses() : Promise.resolve(),
      this.fetchAllSystems(),
      this.fetchPageDebounced(this.pageNumber, this.pageSize, this.latestFilterData, this.latestSorting)
    ]

    try {
      this.amountOfBusyRequests++
      await Promise.all(requests)
    } catch (error) {
      this.showSnackbar({
        role: 'error',
        messages: [this.$t('errors.loading-data-failed')],
        duration: 5000
      })
    } finally {
      this.amountOfBusyRequests--
    }
  },
  watch: {
    pageNumber (newVal, oldVal) {
      // Do nothing if the route is already correct.
      if (this.$route.query.page && parseInt(this.$route.query.page) === newVal) return
      // Replace the page number in the route/url to be the new page number.
      const query = { ...this.$route.query, page: newVal }
      this.$router.replace({ query })
    }
  }
}
</script>
