<template>
  <div>
    <div class="d-flex flex-wrap checkbox-wrapper">
      <v-checkbox
        v-if="isAdmin"
        v-model="includeSuccessful"
        :label="$t('sessions.filter.include-successful-label')"
        hide-details
        style="display: inline"
      />
      <v-checkbox
        v-if="isAdmin"
        v-model="includeArchive"
        :label="$t('sessions.filter.include-archive-label')"
        hide-details
      />
    </div>
    <v-autocomplete
      v-if="isAdmin"
      :label="$t('sessions.filter.projects-label')"
      v-model="selectedProjectIds"
      :items="projects"
      item-value="id"
      item-text="name"
      chips
      clearable
      :search-input.sync="search.projects"
      @change="search.projects = ''"
      deletable-chips
      multiple
      prepend-icon="mdi-group"
    />
    <v-autocomplete
      :label="$t('sessions.filter.systems-label')"
      v-model="selectedSystemIds"
      :items="systems"
      item-value="id"
      item-text="name"
      chips
      clearable
      :search-input.sync="search.systems"
      @change="search.systems = ''"
      deletable-chips
      multiple
      prepend-icon="mdi-laptop"
    />
    <v-autocomplete
      v-if="isAdmin"
      :label="$t('sessions.filter.software-versions-label')"
      v-model="selectedSoftwareVersionIds"
      :items="softwareVersions"
      item-value="id"
      :item-text="softwareVersionToString"
      chips
      clearable
      :search-input.sync="search.softwareVersions"
      @change="search.softwareVersions = ''"
      deletable-chips
      multiple
      prepend-icon="mdi-code-not-equal-variant"
    />
    <v-autocomplete
      :label="$t('sessions.filter.vehicles-label')"
      v-model="selectedVehicleIds"
      :items="vehicles"
      item-value="id"
      :item-text="vehicle => vehicle.name ? vehicle.name : vehicle.uniqueIdentifier"
      chips
      clearable
      :search-input.sync="search.vehicles"
      @change="search.vehicles = ''"
      deletable-chips
      multiple
      prepend-icon="mdi-dump-truck"
    />
    <v-autocomplete
      :label="$t('sessions.filter.drivers-label')"
      v-model="selectedDriverIds"
      :items="drivers"
      item-value="id"
      item-text="name"
      chips
      clearable
      :search-input.sync="search.drivers"
      @change="search.drivers = ''"
      deletable-chips
      multiple
      prepend-icon="mdi-steering"
    />
    <v-autocomplete
      v-if="isAdmin"
      :label="$t('sessions.filter.error-causes-label')"
      v-model="selectedErrorCauseIds"
      :items="errorCauses"
      item-value="id"
      item-text="description"
      chips
      clearable
      :search-input.sync="search.errorCauses"
      @change="search.errorCauses = ''"
      deletable-chips
      multiple
      prepend-icon="mdi-layers-remove"
    />
    <v-autocomplete
      v-if="isAdmin"
      :label="$t('sessions.filter.errors-label')"
      v-model="selectedErrors"
      :items="allErrors"
      chips
      clearable
      :search-input.sync="search.errors"
      @change="search.errors = ''"
      deletable-chips
      multiple
      prepend-icon="mdi-alert-circle"
    />

    <!-- Date -->
    <v-menu
      ref="dateRangePicker"
      v-model="datePickerVisible"
      :close-on-content-click="false"
      :return-value.sync="selectedDateRange"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateRangeText"
          :label="$t('sessions.filter.period-label')"
          prepend-icon="mdi-calendar"
          readonly
          clearable
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="selectedDateRange"
        scrollable
        no-title
        range
        show-current
        show-adjacent-months
        elevation="4"
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="datePickerVisible = false"
        >
          {{ $t('common.buttons.cancel') }}
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.dateRangePicker.save(selectedDateRange)"
        >
          {{ $t('common.buttons.ok') }}
        </v-btn>
      </v-date-picker>
    </v-menu>

    <!-- Buttons -->
    <div class="d-flex flex-wrap justify-center mt-4 filter-buttons">
      <v-btn
        color="secondary"
        class="me-2"
        large
        @click="onResetClicked"
        :loading="loading"
      >
        {{ $t('sessions.filter.reset-button') }}
      </v-btn>
      <v-btn
        color="primary"
        class="ms-2"
        large
        @click="onSearchClicked"
        :loading="loading"
      >
        {{ $t('sessions.filter.search-button') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import semanticVersionHelper from '@/components/helpers/semanticVersionHelper.js'

export default {
  props: {
    /**
     * Indicates whether the filter is busy.
     */
    loading: { type: Boolean, default: false }
  },
  emits: ['searchRequested', 'filterChanged'],
  data () {
    return {
      search: {
        projects: '',
        systems: '',
        softwareVersions: '',
        trucks: '',
        drivers: '',
        errorCauses: '',
        errors: ''
      },
      datePickerVisible: false
    }
  },
  computed: {
    ...mapState('sessionFilter', ['sessionFilter']),
    ...mapState('projects', ['projects']),
    ...mapState('systems', ['systems']),
    ...mapState('softwareVersions', ['softwareVersions']),
    ...mapState('vehicles', ['vehicles']),
    ...mapState('drivers', ['drivers']),
    ...mapState('errorCauses', ['errorCauses']),
    ...mapState('errors', ['allErrors']),
    ...mapState('preferences', ['timezone']),
    ...mapGetters('account', ['isAdmin']),
    includeSuccessful: {
      get () {
        return this.sessionFilter.includeSuccessful
      },
      set (newValue) {
        this.updateProp('includeSuccessful', newValue)
      }
    },
    includeArchive: {
      get () {
        return this.sessionFilter.includeArchive
      },
      set (newValue) {
        this.updateProp('includeArchive', newValue)
      }
    },
    selectedProjectIds: {
      get () {
        return this.sessionFilter.projectIds
      },
      set (newValue) {
        this.updateProp('projectIds', newValue)
      }
    },
    selectedSystemIds: {
      get () {
        return this.sessionFilter.systemIds
      },
      set (newValue) {
        this.updateProp('systemIds', newValue)
      }
    },
    selectedSoftwareVersionIds: {
      get () {
        return this.sessionFilter.softwareVersionIds
      },
      set (newValue) {
        this.updateProp('softwareVersionIds', newValue)
      }
    },
    selectedVehicleIds: {
      get () {
        return this.sessionFilter.vehicleIds
      },
      set (newValue) {
        this.updateProp('vehicleIds', newValue)
      }
    },
    selectedDriverIds: {
      get () {
        return this.sessionFilter.driverIds
      },
      set (newValue) {
        this.updateProp('driverIds', newValue)
      }
    },
    selectedErrorCauseIds: {
      get () {
        return this.sessionFilter.errorCauseIds
      },
      set (newValue) {
        this.updateProp('errorCauseIds', newValue)
      }
    },
    selectedErrors: {
      get () {
        return this.sessionFilter.errors
      },
      set (newValue) {
        this.updateProp('errors', newValue)
      }
    },
    selectedDateRange: {
      get () {
        if (!this.sessionFilter.from) return []
        const from = moment(this.sessionFilter.from)
        const result = [from.format('YYYY-MM-DD')]

        if (this.sessionFilter.to) {
          const to = moment(this.sessionFilter.to)

          // If the dates are the same day...
          // We should only set the from date, otherwise it becomes impossible to select a range.
          if (to.isSame(from, 'day')) return result

          result.push(to.format('YYYY-MM-DD'))
        }

        return result
      },
      set (newValue) {
        if (newValue.length === 0) {
          this.updateProp('from', null)
          this.updateProp('to', null)
        } else if (newValue.length === 1) {
          const selectedDate = moment.tz(newValue[0], this.timezone)
          const from = selectedDate.startOf('day').format()
          const to = selectedDate.endOf('day').format()
          this.updateProp('from', from)
          this.updateProp('to', to)
        } else {
          newValue.sort()
          const from = moment.tz(newValue[0], this.timezone).startOf('day').format()
          const to = moment.tz(newValue[1], this.timezone).endOf('day').format()
          this.updateProp('from', from)
          this.updateProp('to', to)
        }
      }
    },
    dateRangeText: {
      get () {
        return this.selectedDateRange.join(' ~ ')
      },
      set (newValue) {
        if (!newValue) {
          this.updateProp('from', null)
          this.updateProp('to', null)
        }
      }
    }
  },
  methods: {
    ...mapActions('sessionFilter', ['resetSessionFilter']),
    ...mapMutations('sessionFilter', ['setSessionFilterProperty']),
    onResetClicked () {
      this.resetSessionFilter()
      this.$emit('searchRequested', this.sessionFilter)
    },
    onSearchClicked () {
      this.$emit('searchRequested', this.sessionFilter)
    },
    updateProp (name, newValue) {
      this.setSessionFilterProperty({ propertyName: name, newValue: newValue })
      this.$emit('filterChanged', this.sessionFilter)
    },
    softwareVersionToString (softwareVersion) {
      return semanticVersionHelper.versionToString(softwareVersion)
    },
    /** Changes the timezones of the filter dates, in case the user's timezone changed. */
    fixSessionFilterDates () {
      if (this.sessionFilter.from) {
        const from = this.changeTimezoneWithoutChangingDateTime(this.sessionFilter.from, this.timezone)
        this.setSessionFilterProperty({ propertyName: 'from', newValue: from.format() })
      }

      if (this.sessionFilter.to) {
        const to = this.changeTimezoneWithoutChangingDateTime(this.sessionFilter.to, this.timezone)
        this.setSessionFilterProperty({ propertyName: 'to', newValue: to.format() })
      }
    },
    /**
     * Changes the timezone of a date without changing its date/time information.
     * @param {String} date The date as an (ISO) string.
     * @param {String} newTimeZone The new timezone to apply.
     * @returns {Moment} A Moment with the new timezone applied.
     */
    changeTimezoneWithoutChangingDateTime (date, newTimeZone) {
      // Supplying the format (without timezone) and preferred timezone as parameters will result the same date+time, but with a different timezone.
      return moment.tz(date, 'YYYY-MM-DDTHH:mm:ss', newTimeZone)
    }
  },
  created () {
    this.fixSessionFilterDates()
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

// This will put the checkboxes next to eachother instead of below eachother.
.checkbox-wrapper  {
  margin-top: 12px;
  margin-bottom: 16px;
  > * {
    display: inline-block;
    margin-right: 15px;
  }
}

// On very large devices...
.filter-buttons > * {
  width: 175px;

  // On large devices...
  @media #{map-get($display-breakpoints, 'lg-and-down')} {
    width: 115px;
  }
  // On medium devices...
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    width: 115px;
  }
  // On small devices...
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
      width: 100px;
  }
}
</style>
